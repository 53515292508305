import "./App.css";

import ToyotaCorollaImg from "./images/toyota-corolla.jpg";
import LearnerPlateImg from "./images/learner_plate.png";


function App() {
  return (
    <div className="App">
      <img className="background-img" src={ToyotaCorollaImg} alt="" />
      <div className="carousel-caption background-img-content">
        <h1 className="text-light"><img src={LearnerPlateImg} alt="L"/>EARN TO DRIVE</h1>
        <h2 className="text-light">with <strong>y2 Driving School</strong></h2>
      </div>

      <div className="container mt-5">
        <h1 className="float-left row col">Services</h1>
        <div className="card-deck row col">
        <div className="card border-dark mb-3 mx-1">
            <div className="card-body">
              <h3 className="card-title">Driving Lessons</h3>
              <p className="card-text font-weight-bold">(Automatic Transmission)</p>
            </div>
          </div>
          <div className="card border-dark mb-3 mx-1">
            <div className="card-body">
              <h3 className="card-title">Instructor Car Rental</h3>
              <p className="card-text font-weight-bold">(for driving tests)</p>
            </div>
          </div>
        </div>
        <div className="text-left col">
          <p>
            Tip: By using an instructor's car during your test,
            your examiner will feel <b>safer</b> and <b>relaxed</b> as
            they will have control of the vehicle while you are driving.
          </p>
        </div>
        

        <h1 className="float-left row col mt-3">Pricing</h1>
        <div className="card-deck row col">
          <div className="card border-dark mb-3 mx-1">
            <div className="card-body">
              <h3 className="card-title"><strong>1</strong> hour driving lesson</h3>
              <h1 className="card-body">$50</h1>
            </div>
          </div>
          <div className="card border-dark mb-3 mx-1">
            <div className="card-body">
              <h3 className="card-title"><strong>2</strong> hour driving lesson</h3>
              <h1 className="card-body">$100</h1>
            </div>
          </div>
          <div className="card border-dark mb-3 mx-1">
            <div className="card-body">
              <h3 className="card-title">Rent instructor car for driving test</h3>
              <h1 className="card-body">$180</h1>
            </div>
          </div>
        </div>


        <h1 className="float-left row col mt-3">Service areas</h1>
        <div className="text-left col">
          <p>
            We provide high quality driving lessons within the Southern
            and Eastern Sydney suburbs.
            <br />
            <br />
            A few of the locations you can commonly see us at include:
          </p>
          <ul>
            <li>Rockdale</li>
            <li>Kogarah</li>
            <li>Hurstville</li>
            <li>Botany</li>
            <li>Matraville</li>
            <li>Mascot</li>
          </ul>
          <p>
            If you have any questions please contact us.
          </p>
        </div>
        

        <h1 className="float-left row col mt-3">Contact</h1>
        <div className="text-left row col mx-1">
          <p>
            If you wish to consult us regarding our services, or want
            to book a lesson, feel free to send us a message or give
            a ring:
            <br />
            <br />
            Instructor name: <strong>Mohammad Mustafa</strong>
            <br />
            Contact number: <strong>0425 293 920</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
