import React from "react";

import "./Footer.css";


class Footer extends React.Component {
    render() {
        return (
            <footer className="text-center text-lg-start bg-dark text-light mt-3">

                <p className="p-3">
                    y2 Driving School
                </p>
            </footer>
        );
    }
}

export default Footer;