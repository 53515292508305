import React from "react";
import "./Navbar.css";

import {
    Navbar,
    Nav
} from "react-bootstrap";

class Header extends React.Component {
    render() {
        return (
            <Navbar className="sticky-top shadow-sm bg-dark" collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand className="h1 mb-0" href="#home">y2 Driving School</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Item className="">
                            <Nav.Link href="#home" eventKey="#home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                            <Nav.Link href="#services" eventKey="#services">Services</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                            <Nav.Link href="#pricing" eventKey="#pricing">Pricing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                            <Nav.Link href="#service-areas" eventKey="#service-areas">Service areas</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                            <Nav.Link href="#contact" eventKey="#contact">Contact</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header;